import React from 'react';
import Layout from '@components/layout';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useMedia } from '@src/lib/hooks';
import LanaContainer from '../lana/lanaContainer';

import * as styles from './befintligt-lan.module.css';
import Contact from '../../components/contact';
import Hero from '../../components/hero';

export default function LoanExistsPage({ data }) {
  const pageContent =
    data.contentfulPage.pageContent[0].bodyText.childMarkdownRemark.html;
  const title = data.contentfulPage.pageHeader;

  const isMobile = useMedia(['(max-width: 896px)'], [true], false);
  return (
    <Layout path="/kredit/befintligt-lan" newStyle>
      <Hero title={title} />
      <LanaContainer padded={isMobile}>
        <div
          className={styles.loan_exist}
          dangerouslySetInnerHTML={{ __html: pageContent }}
        ></div>
      </LanaContainer>
      <Contact />
    </Layout>
  );
}
LoanExistsPage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query LoanExistsCreditPageQuery {
    contentfulPage(pageUrl: { eq: "/kredit/befintligt-lan" }) {
      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      pageHeader
    }
  }
`;
